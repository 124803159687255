import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import IconLock from 'cccisd-icons/lock2';
import { Link } from 'react-router-dom';
import style from './style.css';

class Welcome extends React.Component {
    static propTypes = {
        params: PropTypes.object,
        history: PropTypes.object,
    };

    getPeriod = (dateStart, dateEnd) => `${format(dateStart, 'MMM D')} - ${format(dateEnd, 'MMM D')}`;

    renderButton = ({ title, enable, linkTo = '/' }) =>
        enable ? (
            <Link to={linkTo} className="btn btn-primary">
                {title}
            </Link>
        ) : (
            <button type="button" className="btn btn-secondary" disabled>
                {title}
                <div>
                    <IconLock />
                </div>
            </button>
        );

    render() {
        const {
            memberSurveyStartDate,
            memberSurveyEndDate,
            // secondarySurveyStartDate,
            // secondarySurveyEndDate,
            staffSurveyStartDate,
            staffSurveyEndDate,
            adminCenterStartDate,
            adminCenterEndDate,
        } = this.props.params.dates;
        const { staffSurveyOn, memberSurveyOn, adminCenterOn } = this.props.params;

        return (
            <div>
                <div className={style.block}>
                    <h3>Welcome to the NYOI 2025 Survey</h3>

                    <div className={[style.children, style.sections].join(' ')}>
                        <p>Log in to access the survey administration center</p>
                        <p>Click here to administer the NYOI Member Survey</p>
                        <p>Click here to take the NYOI Staff Survey</p>
                    </div>
                    <div className={[style.children, style.buttons].join(' ')}>
                        {this.renderButton({
                            title: (
                                <div>
                                    Admin Center
                                    <br />
                                    Access Login
                                </div>
                            ),
                            enable: adminCenterOn,
                            linkTo: '/adminCenter',
                        })}
                        {this.renderButton({
                            title: 'Member Survey',
                            enable: memberSurveyOn,
                            linkTo: '/member',
                        })}
                        {this.renderButton({
                            title: 'Staff Survey',
                            enable: staffSurveyOn,
                            linkTo: '/staff',
                        })}
                    </div>
                    <div className={[style.children, style.dates].join(' ')}>
                        <div>Open {this.getPeriod(adminCenterStartDate, adminCenterEndDate)}</div>
                        <div>Open {this.getPeriod(memberSurveyStartDate, memberSurveyEndDate)}</div>
                        <div>Open {this.getPeriod(staffSurveyStartDate, staffSurveyEndDate)}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    params: state.app.params.data,
});

export default connect(mapStateToProps)(Welcome);
